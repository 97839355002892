import React from 'react';
import styled from 'styled-components';

import { colors } from '../../theme/colors';

interface ProofRowProps {
  providerName: string;
  extractedValue: string;
  createdAtTime: string;
}

export const ProofRow: React.FC<ProofRowProps> = ({ providerName, extractedValue, createdAtTime }: ProofRowProps) => {
  /*
   * Helpers
   */

  const formatExtractedValue = (value: string) => {
    console.log('Input value:', value);

    try {
      let parsed = JSON.parse(value);
      if (typeof parsed === 'string') {
        parsed = JSON.parse(parsed);
      }

      console.log('Parsed extractedValue:', parsed);

      if (typeof parsed !== 'object' || parsed === null) {
        throw new Error('Parsed value is not an object');
      }

      const result = Object.entries(parsed).map(([key, value]) => {
        const humanReadableKey = key
          .split(/(?=[A-Z])/)
          .join(' ')
          .replace(/^\w/, (c) => c.toUpperCase());
        return `${humanReadableKey}: ${value}`;
      });

      console.log('Formatted result:', result);

      return result.length > 0 ? result : [`Valid ${providerName} account`];
    } catch (error) {
      console.error('Error in formatExtractedValue:', error);

      return [`Valid ${providerName} account`];
    }
  };

  /*
   * Handlers
   */

  return (
    <Container>
      <ProofDetailsContainer>
        <DetailsContainer>
          <SummaryLabelValue>{providerName}</SummaryLabelValue>
        </DetailsContainer>

        <DetailsContainer>
          {formatExtractedValue(extractedValue).map((line, index) => (
            <SummaryLabelValue key={index}>{line}</SummaryLabelValue>
          ))}
        </DetailsContainer>

        <DetailsContainer>
          <SummaryLabelValue>{createdAtTime}</SummaryLabelValue>
        </DetailsContainer>
      </ProofDetailsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const ProofDetailsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem 1.5rem;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SummaryLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
