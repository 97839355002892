import React from 'react';
import styled from 'styled-components';

import { Button } from '../../common/Button';
import { colors } from '../../../theme/colors';

export const TopNav: React.FC<{ withoutLinks?: boolean }> = ({ withoutLinks }) => {
  const handleContactClick = () => {
    const emailAddress = 'team@withcred.com';
    const subject = 'Inquiry from Cred Demo';
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`;

    window.open(mailtoLink, '_blank');
  };

  /*
   * Component
   */

  return (
    <NavBar>
      <LogoContainer>
        <LogoAndNavItems>
          <LogoAndTitle>
            <img src={`${process.env.PUBLIC_URL}/logo512.png`} alt="logo" />
            Cred
          </LogoAndTitle>
        </LogoAndNavItems>
      </LogoContainer>

      {!withoutLinks && (
        <NavItemsAndButton>
          <Button height={40} onClick={() => handleContactClick()}>
            Get in Touch
          </Button>
        </NavItemsAndButton>
      )}
    </NavBar>
  );
};

const NavBar = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 70vw;
  min-width: 680px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  padding: 1.5rem;
`;

const LogoAndNavItems = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NavItemsAndButton = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
`;

const LogoAndTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${colors.darkText};
  font-size: 32px;
  text-decoration: none;

  img {
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
`;
