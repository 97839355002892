import { useState } from "react";

type ApiResponse<T> = {
  data: T | null;
  error: ApiError | null;
};

type ApiError = {
  code: number;
  message: string;
};

export default function useApiClient() {
  const [loading, setLoading] = useState<boolean>(false);

  const fetchFromApi = async <T>(
    endpoint: string,
    method = "GET",
    body?: Record<string, unknown>,
  ): Promise<ApiResponse<T>> => {
    setLoading(true);

    try {
      const url = new URL(endpoint, window.location.origin);
      const options: RequestInit = {
        method,
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (body) {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(url.toString(), options);
      const data = await response.json();

      if (response.ok) {
        setLoading(false);
        return { data, error: null };
      } else {
        setLoading(false);
        return {
          data: null,
          error: {
            code: response.status,
            message: data.message || "An error occurred",
          },
        };
      }
    } catch (err) {
      setLoading(false);
      return {
        data: null,
        error: {
          code: 0,
          message: "Network error",
        },
      };
    }
  };

  const get = <T>(endpoint: string) => fetchFromApi<T>(endpoint);

  const post = <T>(endpoint: string, body?: Record<string, unknown>) => fetchFromApi<T>(endpoint, "POST", body);

  const put = <T>(endpoint: string, body?: Record<string, unknown>) => fetchFromApi<T>(endpoint, "PUT", body);

  const del = <T>(endpoint: string) => fetchFromApi<T>(endpoint, "DELETE");

  return { loading, get, post, put, del };
}
