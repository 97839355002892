import React, { useEffect, useState, useRef } from 'react';
import QRCode from 'qrcode';
import styled from 'styled-components';

import ProviderSelect from '../components/Demo/ProviderSelect';
import ProofTable from '../components/Demo/ProofTable';
import { ThemedText } from '../theme/text';
import { colors } from '../theme/colors';
import { useFetchProviders, Provider } from '../hooks/useFetchProviders';
import { useCreateSession } from '../hooks/useCreateSession';

export const Landing: React.FC = () => {
  const qrCodeRef = useRef<HTMLCanvasElement>(null);

  /*
   * State
   */

  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);

  const [sessionId, setSessionId] = useState<string | null>(null);

  /*
   * Hooks
   */

  const { sessionId: fetchedSessionId, createSession } = useCreateSession();

  useEffect(() => {
    const initSession = async () => {
      const storedSessionId = localStorage.getItem('sessionId');

      if (storedSessionId) {
        setSessionId(storedSessionId);
      } else {
        await createSession();
      }
    };

    initSession();
  }, []);

  useEffect(() => {
    if (fetchedSessionId) {
      setSessionId(fetchedSessionId);

      localStorage.setItem('sessionId', fetchedSessionId);
    }
  }, [fetchedSessionId]);

  const { providers, refetch: fetchProviders } = useFetchProviders();

  useEffect(() => {
    const fetchProvidersAsync = async () => {
      await fetchProviders();
    };

    fetchProvidersAsync();
  }, []);

  /*
   * Helpers
   */

  const formatProviderLink = (provider: Provider | null, sessionUUID: string | null): string => {
    if (!provider || !sessionUUID) {
      return '';
    }

    const providerLink = `https://aasa.zkp2p.xyz/integrations?integration=${provider.integrationId}&sessionId=${sessionUUID}`;

    return providerLink;
  };

  const link = formatProviderLink(selectedProvider, sessionId);

  useEffect(() => {
    if (link && qrCodeRef.current) {
      QRCode.toCanvas(qrCodeRef.current, link, { width: 148, margin: 2 }, (error) => {
        if (error) console.error(error);
      });
    }
  }, [link]);

  /*
   * Component
   */

  return (
    <PageWrapper>
      <TitleAndSubtitleContainer>
        <ThemedText.HeadlineMedium>Customer Data Verification Demo</ThemedText.HeadlineMedium>

        <ThemedText.SubHeader>
          Build customer data verification directly into your product flows, accelerating onboarding, checkout, and
          dispute resolution
        </ThemedText.SubHeader>
      </TitleAndSubtitleContainer>

      <BodyContainer>
        <ProviderSelectAndQRContainer>
          <ProviderSelectorContainer>
            <ThemedText.LabelSmall>Choose a data source to begin</ThemedText.LabelSmall>

            <ProviderSelect
              providers={providers}
              onSelect={(provider: Provider) => {
                console.log(provider);

                setSelectedProvider(provider);
              }}
            />

            {sessionId && <ThemedText.BodySmall>Session: {sessionId}</ThemedText.BodySmall>}
          </ProviderSelectorContainer>

          <VerticalDivider />

          {selectedProvider ? (
            <QRContainer>
              <canvas ref={qrCodeRef} />
            </QRContainer>
          ) : (
            <EmptyQRContainer>QR Code</EmptyQRContainer>
          )}
        </ProviderSelectAndQRContainer>

        {sessionId && <ProofTable sessionId={sessionId ?? ''} />}
      </BodyContainer>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: flex-start;
`;

const BodyContainer = styled.div`
  min-width: 664px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
`;

const ProviderSelectAndQRContainer = styled.div`
  width: 640px;
  min-height: 200px;
  display: flex;
  padding: 1.5rem 2rem;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  gap: 1.5rem;
  background-color: white;
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.01),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`;

const ProviderSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  max-width: 600px;
`;

const VerticalDivider = styled.div`
  height: 36px;
  width: 4px;
  border-radius: 2px;
  background-color: ${colors.darkText};
  margin: 0 auto;
`;

const QRContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
  border-radius: 16px;
  background: #131a2a;
`;

const EmptyQRContainer = styled.div`
  height: 186px;
  width: 186px;
  border: 1px solid ${colors.darkText};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Landing;
