import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { Overlay } from '../common/Overlay';
import { Provider } from '../../hooks/useFetchProviders';
import { ProviderRow } from './ProviderRow';

interface ProviderSelectProps {
  providers: Provider[];
  onSelect: (provider: Provider) => void;
}

const ProviderSelect: React.FC<ProviderSelectProps> = ({ providers, onSelect }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  /*
   * State
   */

  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  /*
   * Handlers
   */

  const handleSelect = (provider: Provider) => {
    setSelectedProvider(provider);

    onSelect(provider);

    setIsOpen(false);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  /*
   * Component
   */

  return (
    <SelectContainer ref={containerRef} onMouseLeave={handleMouseLeave}>
      <SelectButton onClick={() => setIsOpen(!isOpen)}>
        {selectedProvider ? selectedProvider.name : 'Select a source'}
      </SelectButton>

      {isOpen && (
        <>
          <Overlay onClick={handleOverlayClick} />

          <Table>
            {providers.map((provider) => (
              <ProviderRow
                key={provider.integrationId}
                nameText={provider.name}
                descriptionText={provider.description}
                isSelected={selectedProvider?.integrationId == provider.integrationId}
                integrationSvg={provider.logoUrl}
                onRowClick={() => handleSelect(provider)}
              />
            ))}
          </Table>
        </>
      )}
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  position: relative;
  width: 200px;
`;

const SelectButton = styled.button`
  width: 100%;
  padding: 10px 8px 8px 8px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
`;

const Table = styled.div`
  width: 480px;
  max-height: 282px;
  position: absolute;
  margin-top: 0.5rem;
  background-color: white;
  border-radius: 8px;

  overflow-y: auto;
  z-index: 20;
`;

export default ProviderSelect;
