import React from 'react';
import styled from 'styled-components';

import { colors } from '../../theme/colors';

interface ProviderRowProps {
  nameText: string;
  descriptionText: string;
  isSelected: boolean;
  integrationSvg: string;
  onRowClick: () => void;
}

export const ProviderRow: React.FC<ProviderRowProps> = ({
  nameText,
  descriptionText,
  isSelected,
  integrationSvg,
  onRowClick
}: ProviderRowProps) => {
  ProviderRow.displayName = 'ProviderRow';

  return (
    <Container onClick={onRowClick} selected={isSelected}>
      <DetailsContainer>
        <IntegrationSvg src={integrationSvg} />

        <LabelContainer>
          <NameLabel>{nameText}</NameLabel>

          <DescriptionLabel>{descriptionText}</DescriptionLabel>
        </LabelContainer>
      </DetailsContainer>
    </Container>
  );
};

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  padding: 8px 16px;

  ${({ selected }) =>
    selected &&
    `
    background-color: #f0f0f0;
  `}

  ${({ selected }) =>
    !selected &&
    `
    &:hover {
      background-color: #f0f0f0;
    }
  `}
`;

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  padding: 0.5rem 0rem;
`;

const IntegrationSvg = styled.img`
  border-radius: 18px;
  width: 36px;
  height: 36px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const NameLabel = styled.div`
  font-size: 14px;
  color: ${colors.darkText};
`;

const DescriptionLabel = styled.div`
  font-size: 12px;
  color: ${colors.grayText};
`;
