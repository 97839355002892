import React, { useEffect, useState } from 'react';
import { Search } from 'react-feather';
import styled from 'styled-components/macro';

import { RowBetween } from '../layout/Row';
import { ProofRow } from './ProofRow';
import { useFetchProofs, Proof } from '../../hooks/useFetchProofs';
import { ThemedText } from '../../theme/text';
import { colors } from '../../theme/colors';

const FETCH_PROOF_INTERVAL = 10000; // 10 seconds

export interface ProofRowDataType {
  providerName: string;
  extractedValues: string;
  createdAt: string;
}

interface ProofTableProps {
  sessionId: string;
}

const ProofTable: React.FC<ProofTableProps> = ({ sessionId }: ProofTableProps) => {
  /*
   * State
   */

  const [proofRowData, setProofRowData] = useState<ProofRowDataType[]>([]);

  /*
   * Hooks
   */

  const { proofs, refetch: fetchProofs } = useFetchProofs(sessionId);

  useEffect(() => {
    const fetchProofsAsync = async () => {
      await fetchProofs();
    };

    fetchProofsAsync();

    const intervalId = setInterval(fetchProofsAsync, FETCH_PROOF_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (proofs && proofs.length > 0) {
      const processedProofs: ProofRowDataType[] = proofs.map((proof: Proof) => {
        const date = new Date(proof.createdAt);
        const formattedDate = date.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });

        return {
          providerName: proof.providerName,
          extractedValues: JSON.stringify(proof.extractedValues),
          createdAt: formattedDate
        };
      });

      setProofRowData(processedProofs);
    } else {
      setProofRowData([]);
    }
  }, [proofs]);

  /*
   * Component
   */

  return (
    <Container>
      <TitleRow>
        <ThemedText.HeadlineSmall>Verified Data</ThemedText.HeadlineSmall>
      </TitleRow>

      <Content>
        {proofRowData.length === 0 ? (
          <ErrorContainer>
            <ThemedText.BodySmall textAlign="center">
              <SearchIcon strokeWidth={1} />
              <div>Complete the flow on your mobile device to verify data</div>
            </ThemedText.BodySmall>
          </ErrorContainer>
        ) : (
          <>
            <TableHeaderRow>
              <ColumnHeader>Integration</ColumnHeader>

              <ColumnHeader>Authenticated Data</ColumnHeader>

              <ColumnHeader>Timestamp</ColumnHeader>
            </TableHeaderRow>

            <Table>
              {proofRowData.map((proofData, rowIndex) => (
                <StyledListingRow key={rowIndex}>
                  <ProofRow
                    providerName={proofData.providerName}
                    extractedValue={proofData.extractedValues}
                    createdAtTime={proofData.createdAt}
                  />
                </StyledListingRow>
              ))}
            </Table>
          </>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleRow = styled(RowBetween)`
  align-items: flex-end;
  color: ${colors.darkText};
  padding: 1rem 1rem 1rem 1rem;
  height: 76px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  }
`;

const Content = styled.div`
  background-color: ${colors.container};
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.01),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  position: relative;
  border-radius: 16px;

  @media (max-width: 600px) {
    margin: 0 1rem;
  }
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
`;

const SearchIcon = styled(Search)`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 1rem;
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  color: ${colors.darkText};
  border-bottom: 1px solid ${colors.defaultBorderColor};
`;

const ColumnHeader = styled.div`
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

const Table = styled.div`
  font-size: 16px;
  color: #616161;
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.defaultBorderColor};
    border-radius: 4px;
  }

  & > * {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const StyledListingRow = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

export default ProofTable;
