import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Landing } from './pages/Landing';
import { TopNav } from './components/layout/TopNav';

import './App.css';
import './styles.css';

const App = () => {
  /*
   * Component
   */

  return (
    <Router>
      <div className="app-container">
        <TopNav />
        <div className="app-content">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route element={<>Not found</>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
