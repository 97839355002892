import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '../../theme/colors';

interface ButtonProps {
  fullWidth?: boolean;
  width?: number;
  height?: number;
  fontSize?: number;
  bgColor?: string;
  bgColorHover?: string;
  disabledColor?: string;
  loadingColor?: string;
  textColor?: string;
  disabled?: boolean;
  loading?: boolean;
  shadow?: boolean;
  border?: boolean;
  svg?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  fullWidth = false,
  width,
  height = 48,
  fontSize = 16,
  bgColor = colors.buttonDefault,
  bgColorHover = colors.buttonHover,
  disabledColor = colors.buttonDefault,
  loadingColor = colors.buttonDisabled,
  textColor = colors.white,
  disabled = false,
  loading = false,
  shadow = false,
  border = false,
  svg,
  onClick,
  children
}) => {
  const [svgLoaded, setSvgLoaded] = useState(!svg);

  return (
    <BaseButton
      fullWidth={fullWidth}
      width={width}
      height={height}
      fontSize={fontSize}
      bgColor={bgColor}
      disabled={disabled}
      $disabled={disabled}
      $loading={loading}
      $svgLoaded={!svg && svgLoaded}
      $bgColorHover={bgColorHover}
      $disabledColor={disabledColor}
      $loadingColor={loadingColor}
      $textColor={textColor}
      shadow={shadow}
      border={border}
      onClick={onClick}
    >
      <ContentContainer>{children}</ContentContainer>

      {svg && <SVGOverlay src={svg} onLoad={() => setSvgLoaded(true)} onError={() => setSvgLoaded(true)} />}
    </BaseButton>
  );
};

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

interface BaseButtonProps {
  fullWidth?: boolean;
  width?: number;
  height?: number;
  fontSize?: number;
  bgColor?: string;
  $disabled?: boolean;
  $loading?: boolean;
  onClick?: () => void;
  textColor?: string;
  shadow?: boolean;
  border?: boolean;
  children?: React.ReactNode;
}

const BaseButton = styled.button<
  BaseButtonProps & {
    $svgLoaded: boolean;
    $bgColorHover: string;
    $disabledColor: string;
    $loadingColor: string;
    $textColor: string;
  }
>`
  width: ${({ fullWidth, width }) => (fullWidth ? '100%' : width ? `${width}px` : 'auto')};
  height: ${({ height }) => height}px;
  background: ${({ bgColor }) => bgColor || colors.buttonDefault};
  box-shadow: inset -3px -6px 4px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
  padding: 8px ${({ fullWidth }) => (fullWidth ? '0' : '24px')};
  text-align: center;
  color: ${({ $textColor }) => $textColor};
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize}px;
  cursor: pointer;
  display: inline-block;
  transition:
    background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  border: none;
  position: relative;

  &:hover:not([disabled]) {
    background: ${({ $bgColorHover }) => $bgColorHover};
  }

  &:active:not([disabled]) {
    background: ${({ bgColor }) => bgColor};
    box-shadow: inset 0px -8px 0px rgba(0, 0, 0, 0.16);
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      color: ${colors.buttonDisabled};
    `}

  ${({ $loading, $loadingColor }) =>
    $loading &&
    css`
      cursor: wait;
      background: ${$loadingColor};
    `}

  ${({ $svgLoaded }) =>
    !$svgLoaded &&
    css`
      background: transparent;
    `}
`;

const SVGOverlay = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  pointer-events: none;
`;
