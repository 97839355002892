import { useCallback, useState } from "react";
import useApiClient from "./useApiClient";

export type Proof = {
  providerName: string;
  extractedValues: Record<string, string>;
  createdAt: string;
};

type ProofsResponse = {
  success: boolean;
  message: string;
  responseObject: Proof[];
  statusCode: number;
};

export const useFetchProofs = (sessionId?: string) => {
  /*
   * State
   */

  const [proofs, setProofs] = useState<Proof[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  /*
   * Hooks
   */

  const { get } = useApiClient();

  const fetchProofs = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const queryParams = new URLSearchParams();
      if (sessionId) {
        queryParams.append("sessionId", sessionId);
      }

      const queryString = queryParams.toString();

      const url = `/proofs${queryString ? `?${queryString}` : ""}`;

      const response = await get<ProofsResponse>(url);
      if (response.data) {
        setProofs(response.data.responseObject);
      } else if (response.error) {
        setError(response.error.message);
      }
    } catch (err) {
      setError("An error occurred while fetching proofs");
    } finally {
      setLoading(false);
    }
  }, [get, sessionId]);

  return { proofs, loading, error, refetch: fetchProofs };
};
