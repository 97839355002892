export function opacify(amount: number, hexColor: string): string {
  if (!hexColor.startsWith('#')) {
    return hexColor;
  }

  if (hexColor.length !== 7) {
    throw new Error(`opacify: provided color ${hexColor} was not in hexadecimal format (e.g. #000000)`);
  }

  if (amount < 0 || amount > 100) {
    throw new Error('opacify: provided amount should be between 0 and 100');
  }

  const opacityHex = Math.round((amount / 100) * 255).toString(16);
  const opacifySuffix = opacityHex.length < 2 ? `0${opacityHex}` : opacityHex;

  return `${hexColor.slice(0, 7)}${opacifySuffix}`;
}

export const colors = {
  container: '#FFFFFF',

  darkText: '#333333',
  grayText: '#525252',
  lightGrayText: '#F8F9FA',

  white: '#FFFFFF',
  black: '#000000',

  defaultBorderColor: '#C0C0C0',

  buttonDefault: '#23AA79',
  buttonHover: '#1C8861',
  buttonDisabled: '#8AD1B6'
};
